.hotel-info {
  display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      width: 100%;
      background: #202746;
      border-radius: 10px;
      flex-wrap: wrap;
      color: white;
      padding: 1rem;
      text-align: left;
      box-sizing: border-box;
      flex-wrap: nowrap;
      box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
  }
  
  .hotelicon {
    width: 3rem;
    object-fit: contain;
  }

  .font-medium {
    font-size: 0.8rem;
  }
  .row {
    flex-direction: row;
  }
  
  .hotel-info-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  
  .hotelroute {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }
  
  .airport-code {
    font-size: 1rem;
    letter-spacing: 0.05em;
  }
  
  .arrow {
    color: #666;
  }
  
  .hoteldates {
    color: #666;
  }
  
  .hotelsearch {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #1a73e8;
    color: white;
    text-decoration: none;
    border-radius: 0.25rem;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .hotelsearch:hover {
    background-color: #1557b0;
  }