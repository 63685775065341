body {
  margin: 0;
  font-family: "MusicaPro", sans-serif;
  background: white;
  line-height: 1.2;
  font-weight: 400;
}

h1, h3, p {margin: 0}
.logo {
  display: block;
  margin: 1rem;
  width: 15rem; /* Imposta la larghezza desiderata */
  height: auto; /* Mantieni le proporzioni */
  margin-top: 20px; /* Aggiungi un po' di spazio sopra l'immagine */
}

.festival-card-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 1rem;
  margin: 1rem 0;
}

@media (max-width: 1024px) {
  .festival-card-container {
      flex-direction: column;
    }
}


.home-container {
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(32, 39, 70);
  max-width: 1280px;
}

.animated {
  display: flex;
  justify-content: center;
  height: 10vh;
  align-items: center;
  background: #4CAF50;
  
}
.home-subtitle {
  color: #202746;
  text-align: justify;
  font-weight: 600;
  font-size: 1rem;
  user-select: none;
}

.home-text-1 {
  color: #202746;
  font-size: 1.5rem;
  text-align: justify;
  font-weight: 600;
  margin: 0;
}

.home-text-2 {
  color: #202746;
  font-size: 2rem;
  font-weight: 800;
  text-align: justify;
  margin: 2rem 0;
  text-transform: uppercase;
  line-height: 1;
}

.home-cit {
  color: #202746;
  font-size: 2rem;
  font-weight: 800;
  text-align: justify;
  margin: 0;
  margin-bottom: 2rem;
  text-transform: uppercase;
  line-height: 1;
}

.centered-text {
  max-width: 100%; /* Limita la larghezza massima per una migliore leggibilità */
  padding: 1rem 1.5rem;
}

.home-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7vmax;
  text-align: center;
  color: white ;
  height: 15vh;
  font-weight: 800;
  text-transform: uppercase;
  width: 100%;
  background-color: #202746;
  opacity: 1;
  padding: 1rem 2rem 0 2rem;
  box-sizing: border-box;
  user-select: none;
  width: 100vw;
  max-width: 1280px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.spotify-player-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  height: auto;
  max-width: 500px;
}
.bubble-button {
  /* background-color: #000000; */
  background: transparent;
  color: #202746;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 0;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  font-variation-settings: "slnt" 0;
  cursor: pointer;
  border-radius: 50px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, opacity 1s ease-in-out;
}

.bubble-button:hover {
  transform: scale(1.1);
  animation-play-state: paused; /* Ferma l'animazione all'hover */
}

.partner-logo-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  height: 100px;
}


.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  width: 100%;
}

.bubble-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 5px solid #ee4d56 ; /* Bordo rosso trasparente */
  box-sizing: border-box;
  animation: wave 3s infinite;
}

.bubble-button:hover::before {
  animation-play-state: paused; /* Ferma l'animazione all'hover */
}

@keyframes wave {
  0% {
    transform: scale(1);
    border-radius: 60px;
  }
  50% {
    transform: scale(0.95);
    border-radius: 40px;
  }
  100% {
    transform: scale(1);
    border-radius: 70px;
  }
}

.flight-link {
  color: #ffffff; /* White text color */
  padding: 10px 20px; /* Padding for spacing */
  background-color: #007bff; /* Blue background color */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Inline block for better spacing */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.form-container {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  transition: all 0.5s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container form {
  display: flex;
  flex-direction: column;
}

.form-container label {
  margin-bottom: 10px; /* Aumenta il margine inferiore */
  font-size: 18px;
  color: #333;
}

.form-container input[type="text"],
.form-container input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 3px solid #ccc;
  border-radius: 5px;
  margin-top: 10px; /* Aumenta il margine superiore */
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.form-container input[type="text"]:focus,
.form-container input[type="text"]:focus {
  border-color: #4CAF50; /* Verde */
  outline: none;
}

.form-container button[type="submit"] {
  background-color: #4CAF50; /* Verde */
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-container button[type="submit"]:hover {
  background-color: #45a049; /* Verde scuro */
}

.input-form-date {
  padding: 10px;
  font-size: 16px;
  border: 3px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  
}

.flight-link {
  margin: 1rem 0;
}
