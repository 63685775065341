.footer {
    background-color: #202746;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    height: 5vh;
  }
  
  .footer-links {
    margin-top: 10px;
  }
  
  .footer-links a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }