.train-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    background: #202746;
    border-radius: 10px;
    flex-wrap: wrap;
    color: white;
    padding: 1rem;
    text-align: left;
    box-sizing: border-box;
    flex-wrap: nowrap;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
}

.train-button-search-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
}

.train-search {
    color: #FFA666;
    text-decoration: none;
    border: 3px solid #FFA666;
    border-radius: 10px;
    padding: 0.5rem;
    min-width: fit-content;
    text-align: center;
    font-size: 1.1rem;
}

.train-info-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    font-size: 0.8rem;
    
}


.train-icon {
    filter: invert(0.9);
    width: 3rem;
    margin: -10px 0;
}
.icon-container {
    width: 3.5rem;
}

.train-station-departure {
    font-size: 0.8rem;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    max-width: calc(50% - 1rem);
    text-align: left;
}

.train-station-arrival {
    font-size: 0.8rem;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    max-width: calc(50% - 1rem);
    text-align: right;
}
