.services-container {
  width: 100%;
  display: flex;
}

.services-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-evenly;
  padding: 0;
  margin: 0;
  padding: 1rem;
  width: 100%;
  background: rgb(32, 39, 70);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
  transition: box-shadow 0.3s ease;
}

.service-card {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
 
}



.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.2rem;
}

.icon-container img {
width: 1.5rem;
  object-fit: contain;
  filter: invert(1);
}

.loading-icon {
  width: 24px;
  height: 24px;
  background: #e0e0e0;
  border-radius: 50%;
  animation: pulse 1.5s infinite ease-in-out;
}

.error-icon {
  width: 24px;
  height: 24px;
  background: #e0e0e0;
  border-radius: 50%;
}


.service-name {
  text-align: center;
  font-weight: 500;
  color: white;
  font-size: 0.8rem;
  text-decoration: none;
}

a.service-name:hover {
  color: #0066cc;
}


@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}



@media (max-width: 480px) {
  .service-name {
    font-size: 0.6rem;
  }
  
  .services-title {
    font-size: 1rem;
  }
}