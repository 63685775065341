.input-placeholder {
    color: #202746;
    border: none;
    background: transparent;
    border-bottom: 2px solid #202746;
    font-size: 1.5rem;
    box-sizing: border-box;
    border-radius: 0;
    font-weight: 400;
    transition: all 0.2s;
    width: 90%;
    max-width: 800px;
}

.input-placeholder::placeholder {
    color: #202746;
}

.input-placeholder:focus {
    border: none;
    outline: none;
    border-bottom: 3px solid #202746;
}

.distance-airport {
    font-size: 0.5rem
}

.sale-start {
    font-size: 0.6rem;
    margin-top: 0.3rem;
}

.divider {
    width: 100%;
    color: rgb(233, 235, 232);
    padding: 1rem;
    background: rgb(32, 39, 70);
    text-transform: uppercase;
    font-size: 0.8rem;
    box-sizing: border-box;
}
.selected {
    color: white !important;
    background-color: #202746;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 3px 2px;
    max-width: 100% !important;
    min-width: 100% !important;
    transition: width 0s, background-color 0.7s, color 0.5s;
}

.selected-departure {
    color: white !important;
    background-color: #202746 !important;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 3px 2px;
}

.disabled {
    opacity: 0.7;
    cursor: not-allowed;
    /* Cambia il cursore per indicare che l'elemento è disabilitato */
    user-select: none;
    pointer-events: none;
    transition: all 0.5s;
}

.travel-results {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 0.5rem;
    max-width: 800px;
    gap: 1rem;
    transition: all 0.5s;
}

.lineup {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
}


.artist {
    width: fit-content;
    background-color: #202746;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    color: white;
    padding: 0.5rem;
    font-size: 0.6rem;
    
    
}

.selected-artist {
    background-color: white;
    color: #202746;
    box-shadow: rgba(0, 0, 0, 0.7) 1px 3px 3px;
}

.ticket-results {

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-width: 800px;
    gap: 1rem;
    transition: all 0.5s;

}

.max-width {
    width: 100% !important;
    max-width: 100% !important;
}

.ticket-link {
    border-radius: 10px;
    background-color: #202746;
    color: #202746;
    display: flex;
    padding: 0.5rem 1rem;
    width: 100%;
    color: white;
    gap: 1rem;
    width: fit-content;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    border: 3px solid #FFA666;
    font-size: 0.8rem;
    font-family: 'MusicaPro', sans-serif;
}

.selected-date {
    text-transform: uppercase;
    font-size: 1rem;
    border-radius: 10px;
    background-color: #202746;
    color: #202746;
    display: flex;
    padding: 0.5rem 1rem;
    width: 100%;
    color: white;
    gap: 1rem;
    width: fit-content;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    margin: 0;
    border: 3px solid #FFA666;
    font-family: 'MusicaPro', sans-serif;
}

.selected-date-open {
    margin-top: 1rem;
    font-size: 0.8rem;
}

.ticket {
    border: 3px solid #202746;
    cursor: pointer;
    padding: 1rem;
    border-radius: 10px;
    font-weight: 800;
    font-size: 1rem;
    color: #202746;
    width: 100%;
    min-width: 45%;
    max-width: calc(50% - 0.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;


}

.ticket-actions {
    width: 100%;
flex-direction: column;
justify-content: center;
align-items: center;
display: flex;
margin-top: 1rem;
}
.ticket-date {
    font-size: 0.8rem;
    background: transparent;
    border-radius: 5px;
    text-decoration: underline;
    text-decoration-color: white;
    text-decoration-thickness: 3px;
}

.travel-departure {
    border: 3px solid #202746;
    cursor: pointer;
    padding: 1rem;
    border-radius: 10px;
    font-weight: 800;
    font-size: 0.8rem;
    color: #202746;
    transition: all 0.5s;
    display: flex;
    width: 100%;
    min-width: fit-content;
    max-width: fit-content;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background: white;
}

.travel-departure:hover {
    
    transition: all 0.5s;
}

.offers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    max-width: 1000px;
}

.travel-offer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: #202746;
    border-radius: 10px;
    color: white;
    gap: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
}

.carriers-offer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
}

.flight-icon {
    filter: invert(0.9);
    width: 3rem;
    margin: -10px 0;
}

.ticket-icon {
    width: 2rem;
    object-fit: contain;
    filter: invert(0.9);
  }


.airport {
    font-size: 0.8rem;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    max-width: calc(50% - 1rem);
    text-align: left;
}




.end {
    text-align: right;
}
.carrier-name {
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
    text-align: left;
    background: white;
    color: #202746;
    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 5px;
    max-width: fit-content;
    min-width: 60px;
}

.carriers-offer-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
}
.flight-search-button {
    color: #FFA666;
    text-decoration: none;
    border: 3px solid #FFA666;
    border-radius: 10px;
    padding: 0.5rem;
    min-width: fit-content;
    max-width: 30%;
    text-align: center;
    font-size: 1.1rem;

    
}

.flight-search-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
}

.trip-date-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.trip-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    transition: all 0.5s;
    position: relative;
}

.trip-date {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    max-height: 40px;
    background: #202746;
    border-radius: 10px;
    color: white;
    padding: 1rem;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
    z-index: 2;
}

.trip-date-divider {
    position: absolute;
    left: 0;
    width: 100%;
    border-bottom: 3px dashed #202746;
    z-index: 0;
}

.stay22-map {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    opacity: 1;
    transition: all 1s;
    height: 500px;
    max-width: calc(100% - 0.5rem);
    transition: all 0.5s;
    position: relative;
    border: 3px solid #202746;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
}

.nations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    width: 100%;
    
}

.others-label {
    font-size: 0.8rem;
}

.change-nation {
    max-width: 30% !important;
}
.selected-nation {
    padding: 1.5rem !important;
    max-width: fit-content !important;
    font-size: 1rem !important;
}
.nation {
    gap: 0.5rem;
    padding: 0.5rem;
    font-family: 'MusicaPro', sans-serif;
    height: 45px;
    text-transform: uppercase;
    font-size: 0.8rem;
    max-width: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
}