.explore-container {
    box-sizing: border-box;
    padding: 0 1rem;
    width: 100%;
    color: #202746;
    margin: 1rem 0;
    min-height: calc(100vh - 150px - 5vh - 4rem);
    display: flex;
    flex-direction: column;
    align-items: center;
}