/* Stili per il componente di autenticazione Spotify */
.spotify-auth-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 1.5rem;
    background-color: #202746;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.spotify-auth-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    text-align: center;
}

.spotify-icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

.spotify-error-message {
    background-color: #ffebee;
    color: #d32f2f;
    padding: 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    text-align: center;
}

@keyframes pulseBorder {
    0% {
      box-shadow: 0 0 0 0 rgba(29, 185, 84, 0.6); /* Verde Spotify con opacità */
    }
    70% {
      box-shadow: 0 0 0 10px rgba(29, 185, 84, 0.3);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(29, 185, 84, 0);
    }
  }
  
  @keyframes pulseBorder {
    0% {
      box-shadow: 0 0 0 0 rgba(29, 185, 84, 0.4);
    }
    50% {
      box-shadow: 0 0 0 4px rgba(29, 185, 84, 0.2);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(29, 185, 84, 0);
    }
  }
  
  .spotify-login-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: fit-content;
      gap: 1rem;
      padding: 2rem;
      box-sizing: border-box;
      background-color: #202746;
      color: white;
      font-weight: bold;
      border: none;
      margin-top: 0.5rem;
      border-radius: 25px;
      border: 2px solid #1DB954;
      font-size: 1rem;
      height: 7vh;
      cursor: pointer;
      transition: all 0.4s ease;
      animation: pulseBorder 3s ease-in-out infinite;
  }

  .spotify-feature {
    list-style: none;
    text-align: left;
    background: white;
    color: black;
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .spotify-feature-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
  .spotify-login-button:hover {
      background-color: transparent;
      box-shadow: 0 0 10px rgba(29, 185, 84, 0.3);
      transform: translateY(-1px);
  }

.user-profile-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.user-profile-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.user-profile-details {
    flex-grow: 1;
}

.artist-in-festival {
    border: 3px solid #1DB954 !important; /* Verde Spotify */
    position: relative;
    box-shadow: 0 0 10px rgba(29, 185, 84, 0.5);
    transition: transform 0.2s ease-in-out;
  }
  
  .artist-in-festival:hover {
    transform: scale(1.05);
  }
  
  /* Badge per indicare il numero di festival */
  .artist-festivals-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #1DB954;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

.user-profile-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
}

.user-profile-followers {
    color: white;
    font-size: 0.9rem;
}

.spotify-auth-features {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    padding: 0;
    color: white;
}
.spotify-icon-auth {
    width: 30px;
    height: 30px;
    object-fit: contain;
}
.spotify-logout-button {
    padding: 0.5rem 1rem;
    background-color: #ff5a5f;
    color:#202746;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.spotify-logout-button:hover {
    background-color: #ff4349;
}


.top-artists-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
    color: white;
}

.top-artists-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 1rem;
}

.top-artist-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-artist-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 0.5rem;
}

.top-artist-name {
    font-size: 0.8rem;
    text-align: center;
    color: #202746;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-spinner-icon {
    width: 50px;
    height: 50px;
    border: 4px solid #1DB954;
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  color: white;
  font-size: 1.2rem;
  position: relative;
}

.loading-spinner::after {
  content: '';
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top-color: #1DB954; /* Colore verde di Spotify */
  animation: spin 1s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Aggiungi questo al file spotifyAuth.css per supportare le funzionalità del modal */

/* Animazioni per le card degli artisti quando cliccate */
.top-artist-card {
  cursor: pointer;
  transition: all 0.3s ease;
}

.top-artist-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Animazione per l'ingresso del modal */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Classe per l'errore nel modal */
.error-message {
  color: #e74c3c;
  text-align: center;
  padding: 1rem;
  font-size: 0.9rem;
}

/* Stile per il badge migliorato */
.artist-festivals-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #1DB954;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}


.top-artists-grid .top-artist-card:hover::after {
  opacity: 1;
}