.input-placeholder {
    color: #202746;
    width: 100%;
    border: none;
    background: transparent;
    border-bottom: 2px solid #202746;
    font-size: 1.5rem;
    height: 5vh;
    padding: 0;
    box-sizing: border-box;
    border-radius: 0;
    font-weight: 400;
    transition: all 0.2s;
    max-width: 1000px;
}

.input-placeholder::placeholder {
    color: #202746;
}

.input-placeholder:focus {
    border: none;
    outline: none;
    border-bottom: 3px solid #202746;
}