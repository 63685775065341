.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #202746;
  background: white;
  padding: 1rem;
  opacity: 1;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  height: 15vh;
  min-height: 150px;
  padding: 1rem;
  transition: opacity 0.3s ease;
  max-width: 1280px;
}

.header.hidden {
  opacity: 0;
  pointer-events: none;
}

.header-mini {
  display: flex;
  flex-direction: row;
  background: #202746;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 0;
  margin-bottom: 1rem;
  transition: all 0.5s;
  z-index: -1;
  box-sizing: border-box;
  transition: all 0.1s ease-out;
  opacity: 0;
}

.header-mini-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transform: translateY(-100%);
  transition: transform 0.3s ease;
}

.header-mini-wrapper.visible {
  transform: translateY(0);
}

.header-mini-open {
  display: flex;
  flex-direction: row;
  background: rgb(32, 39, 70);
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  padding: 1rem 1em;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  max-height: 100px;
}

.festival-name-bar {
  background: rgb(32, 39, 70);
  color: white;
  width: 100%;
  padding-bottom: 0.5rem;
  text-align: center;
  height: 20px;
  font-size: 0.8rem;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}




.header-logo {
  width: 8rem; /* Imposta la larghezza desiderata per dispositivi mobili */
}

.header-logo-mini {
  width: 6rem; /* Imposta la larghezza desiderata per dispositivi mobili */
}

.icon-header {
  width: 1.5rem;
}

.icon-mini {
  width: 1.5rem;
}

.icons-label {
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.1;
  font-weight: 800;
}

.icons-wrapper {
  width: auto;
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 5px;
  
}

/* Media query per schermi più grandi (desktop) */
@media (min-width: 768px) {
  .logo {
    width: 20rem; /* Imposta la larghezza desiderata per desktop */
  }
}

.header-title {
  font-size: 1.5rem;
  text-align: center;
  color: #333; /* Colore del testo */
}

.header-row-menu {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  z-index: 5;
  min-width: fit-content;
}

.header-row-menu-mini {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 80%;
  gap: 1rem;
  text-align: end;
  line-height: 1;
  z-index: 5;
}

.menu-link {
  text-decoration: none;
  color: #202746;
  font-size: 1.3rem;
  font-weight: 700;
  cursor: pointer;
}

.menu-link.active {
 color: white;
  background: #202746;
  padding: 0.5rem;
  border-radius: 10px;
  margin-right: -0.5rem;
}

.menu-link-separator {
  text-decoration: none;
  color: #202746;
  font-size: 2rem;
  font-weight: 800;
  user-select: none;
  border-radius: 15px;
  width: 3px;
  height: 50px;
  background: #202746;
}

.menu-link-white {
  text-decoration: none;
  color: white;
  font-size: 1.3rem;
  font-weight: 800;
  min-width: fit-content;
}
.menu-link-white.active {
  text-decoration: none;
  color: #202746;
  font-size: 1.3rem;
  background: white;
  border-radius: 10px;
  padding: 0.5rem;
  font-weight: 800;
  min-width: fit-content;
}



.header-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
  gap: 1rem;
}

.menu-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  position: relative;
  width: 100%;
  max-width: calc(60% - 2rem);
  justify-content: flex-end;
  height: 8rem;
  z-index: 5;
}

@media (max-width: 767px) {
  .menu-link-separator {
    display: none;
  }
  .icon-header {
    width: 1.5rem;
  }
  .icons-label {
    font-size: 0.8rem;
  }
  .menu-link {
    font-size: 1rem;
  }
  .menu-link-white {
    font-size: 0.8rem;
  }
  .menu-link-white.active {
    font-size: 0.8rem;
  }
  .header-row-menu {
    flex-direction: column;
    align-items: flex-end;
    gap: 1.5rem;
    margin-right: 0.5rem;
  }
  .menu-wrapper {
    flex-direction: row-reverse;
    justify-content: flex-start;
  }
  .header-mini-open {
    padding: 0.75rem 1rem;
  }
}