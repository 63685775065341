.flight-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .flight-icon {
    width: 3rem;
    object-fit: contain;
  }
  

  .flight-info-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .flight-route {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
  }
  
  .airport-code {
    font-size: 1rem;
    letter-spacing: 0.05em;
  }
  
  .arrow {
    color: #666;
  }
  
  .flight-dates {
    color: #666;
  }
  
  .flight-search {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: #1a73e8;
    color: white;
    text-decoration: none;
    border-radius: 0.25rem;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .flight-search:hover {
    background-color: #1557b0;
  }

  .text-white {
    color: white;
  }