.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
}

.time-left {
    font-size: 2rem;
    font-weight: 700;
}

.countdown-title {
  margin: 0;
  margin-bottom: 1rem;
}