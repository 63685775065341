

  .animate-wave {
    animation: wave 2s ease-in-out infinite;
  }

  .letter {
    margin: 5px 1px;
  }

  /* Ensure smooth animation */
  .animate-wave {
    backface-visibility: hidden;
    perspective: 1000;
    transform-style: preserve-3d;
    will-change: transform;
  }
  @keyframes wave {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }