.festival-card {
    display: flex;
    justify-content: center;
    align-items: center;
    /* White background */
    border-radius: 10px;
    /* Rounded corners */
    /* Subtle shadow */
    padding: 2rem;
    /* Margin outside the card */
    transition: all 0.5s;
    /* Smooth transition for hover effect */

    text-decoration: none;
    box-sizing: border-box;
    position: relative;
    min-width: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    background-blend-mode: hard-light;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.festival-card-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    
    /* White background */
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    gap: 0.5rem;
    text-decoration: none;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    transition: all 0.5s;
}

.festival-card-name-container {
    position: relative;
    font-weight: 800;
    max-width: 100%;
    text-decoration: none;
    box-sizing: border-box;
    text-align: center;
    margin: 0;
    width: 100%;
}

.festival-card-link {
    text-decoration: none;
    font-weight: 800;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-link-mini {
    background: transparent;
}

.festival-card:hover {
    /* Slight lift on hover */
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
}

.card-mini {
    max-width: 45% !important;
    min-height: calc(100px + 2rem);
}

.card-name {
    font-size: 1.5rem;
    /* Font size for the heading */
    color: white;
    margin-bottom: 0.5rem;
    font-weight: 800;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}


.card-name-mini {
    font-size: 1rem;
    color: white;
    padding: 0.5rem 0;
    box-sizing: border-box;
    margin: 0;
    font-weight: 800;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    max-width: 100%;
    text-transform: uppercase;
}
.react-emojis-1 {
    margin:-2px;
}
.flag-icon {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    gap: 0.1rem;
   
}
.festival-city {
    font-size: 0.5rem;
    color: white;
    width: 100%;
    font-weight: 800;
}
.card-date {
    display: flex;
    font-size: 0.7rem;
    /* Font size for the paragraph */
    color: white;
    /* Text color for the paragraph */
    font-weight: 800;
    width: 100%;
    gap: 0.5rem;
    box-sizing: border-box;
    border-radius: 10px;
    text-align: start;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    align-items: center;
    padding: 0.5rem;
    box-sizing: border-box;
}

.card-description {
    /* Font size for the paragraph */
    color: #202746;
    /* Text color for the paragraph */
    margin-top: 1rem;
    font-weight: 400;
    max-width: 90%;
}

.card-location {
    font-size: 1rem;
    color: #202746;
    text-align: start;
    width: 100%;
    text-align: start;
}

.icon {
    width: 1.7rem;
    
}

@media (max-width: 1024px) {
    .festival-card {
        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 768px) {
    .festival-card {
        padding: 1rem;
    }
    .card-mini {
        padding: 0.4rem;
    }
}