.share-container {
    position: relative;
    display: inline-block;
}

.share-button {
    padding: 8px 16px;
    background-color: #202746;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 700;
    font-family: 'MusicaPro', sans-serif;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 3px 2px;
    display: flex;
    gap: 1rem;
    padding: 1rem;
    align-items: center;
    text-transform: uppercase;
}

.share-button:hover {
    background-color: #202746;
}

.share-icon {
    width: 1.5rem;
    filter: invert(0.9);
}


.share-notification {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 8px;
    padding: 8px 12px;
    background-color: #202746;
    color: white;
    border-radius: 10px;
    font-size: 14px;
    white-space: nowrap;
    animation: fadeIn 0.3s ease-in-out;
    font-family: 'MusicaPro', sans-serif;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 3px 2px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, 10px);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}