.festival-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    color: #202746;
    padding: 0 1rem;
    box-sizing: border-box;
    gap: 1rem 0;
    width: 100%;
    min-height: calc(100vh - 150px - 5vh - 4rem);
    max-width: 800px;

}

.link {
    color: white;
    text-decoration: none;

}

.omio-gif {
    width: 100%;
    object-fit: contain;
    max-width: 80%;
    border-radius: 10px;
}

.omio-badge {
    width: 100%;
    object-fit: contain;
}

.link-icon {
    width: 1.5rem;
    filter: invert(0.9);
}

.link:hover {
    text-decoration: underline;
}
.festival-name-text {
    background-color: white;
    
  padding: 7px;
  color: #202746;
  border-radius: 3px;
}
.festival-name {
    font-size: 1.2rem;
    font-weight: 800;
    padding: 1rem;
    width: 100%;
    text-transform: uppercase;
    color: white;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 15vh;
    box-sizing: border-box;
    
}

.festival-info {
    font-size: 1.2rem;
    font-weight: 500;
    text-align: justify;
}

.festival-location {
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    background: #202746;
    color: white;
    padding: 0.5rem;
    line-height: 1;
}

.offers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    max-width: 1000px;
}

.offer {
    width: 100%;
    max-width: 90%;
    max-width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #202746;
    border-radius: 15px;
    color: white;
    padding: 1rem;
}

.loading {
    font-size: 3rem;
    animation: bounce 2s infinite;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.carrier-name {
    font-size: 1rem;
    ;
    max-width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-transform: uppercase;
    text-overflow: ellipsis;
}

.hotel-widget {
    width: 95%;
    height: 100%;
    max-width: 80%;
}

.menu {
    display: flex;
    width: 100%;
    max-width: 800px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.festival-site {
    font-size: 0.8rem;
}
.map {
    width: 100%;
    height: 100%;
    max-width: 500px;
    border-radius: 15px;
    overflow: hidden;
    opacity: 1;
    transition: all 1s;
    min-height: 180px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: all 0.5s;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
}

.map-iframe {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    opacity: 1;
    transition: all 1s;
    max-width: 100%;
    transition: all 0.5s;
    position: relative;
    z-index: 0;
    margin-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 3px 2px;
}

.spotify-player-festival {
    width: 100%;
    height: 100%;
    margin-top: 0.5rem;
    max-width: 800px;
    border-radius: 10px;
    overflow: hidden;
    opacity: 1;
    transition: all 0.5s;
    position: relative;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.map-title {
    font-size: 0.8rem;
    color: white;
    font-weight: 800;
    background: #202746;
    padding: 10px;
    font-weight: 800;
    background: #202746;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
}

.flights {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1000px;
    gap: 1rem;
}


.flights-results {
    max-width: 90%;
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: flex;
    gap: 2rem;
}

.flights-departure {
    border: 3px solid #202746;
    cursor: pointer;
    padding: 1rem;
    border-radius: 10px;
    font-weight: 800;
    font-size: 1rem;
    color: #202746;
    transition: all 0.5s;
    width: 100%;
    min-width: fit-content;
    max-width: fit-content;
    flex: 1;
}

.flights-departure:hover {
    color: white;
    background-color: #202746;
    transition: all 0.5s;
}



.icon {
    font-size: 1.5rem;
}

.gmp-map {
    height: 300px;
    width: 300px;
}

.site-link {
    cursor: pointer;
    padding: 1rem;
    border-radius: 10px;
    font-weight: 800;
    font-size: 1rem;
    background: #202746;
    transition: all 0.5s;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    gap: 1rem;
    max-width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 1px 3px 3px;
}

.open-map-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: calc(100% - 40px);
    left: 0px;
    top: 40px;
    background: rgba(0, 0, 0, 0.4);
}

.open-map-button {
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 10px;
    font-weight: 600;
    line-height: 1;
    font-size: 1rem;
    background: #202746;
    transition: all 0.5s;
    color: #FFA666;
    border: 3px solid #FFA666;
    outline: none;
    font-family: 'MusicaPro', sans-serif;
}

.open-map-button:hover {
    color: white;
}

.site-link-button {
    color: white;
    transition: all 0.5s;
}

.open-map-button:hover .site-link-button {
    color: #FFA666;
}


@media (max-width: 768px) {
    .icon {
        font-size: 1.5rem;
    }
}