.App {
  text-align: center;
  text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background: white;
    user-select: none;
    overflow-x: hidden;
    text-transform: uppercase;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.language-selector {
  position: fixed;
  bottom: 20px;    /* Cambiato da top a bottom */
  right: 20px;     /* Cambiato da left: 50% */
  transform: none; /* Rimosso translateX */
  z-index: 1000;
  display: flex;
  gap: 8px;
  background: rgb(32, 39, 70);  /* Sfondo nero */
  padding: 10px;    /* Padding per il box */
  border-radius: 10px; /* Border radius richiesto */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Aggiunto shadow per profondità */
}

.lang-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  border: 1px solid #333;     /* Scurito il bordo */
  border-radius: 6px;         /* Ridotto leggermente per il contesto */
  background: #222;           /* Sfondo scuro per i bottoni */
  cursor: pointer;
  transition: all 0.3s ease;
  color: #fff;               /* Testo bianco */
}

.lang-btn:hover {
  background: #333;          /* Hover più scuro */
}

.lang-btn.active {
  background: #444;          /* Active più scuro */
  border-color: #555;
}

.flag-icon {
  display: flex;
  align-items: center;
}

.lang-text {
  margin-left: 4px;
  color: #fff;              /* Testo bianco */
}