.about-container {
    box-sizing: border-box;
    padding: 0rem 2rem;
    width: 100%;
    color: #202746;
    min-height: calc(100vh - 150px - 5vh - 4rem);
    max-width: 880px;
    margin: 2rem 0;
    font-size: 1.2rem;
    
}
.festival-title {
    font-size: 1rem;
    font-weight: 800;
    background-color: #202746;
    border-radius: 10px;
    padding: 1rem;
    width: 100%;
    color: white;
    border: 3px solid #FFA666;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

.about-text {
    font-size: 0.8rem;
}
